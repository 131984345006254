import React, {Suspense, lazy} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {ADMIN_URL} from "config/constants";
const AdminApp = lazy(() => import('AdminApp'));
const ExamApp = lazy(() => import('ExamApp'));

const SwitchApp = () => {
  return (
    <Router>
      <Suspense fallback={<div>&nbsp;</div>}>
        <Switch>
          <Route path={`/${ADMIN_URL}`} component={AdminApp}/>
          <Route path="/" component={ExamApp}/>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default SwitchApp;