export const ITEM_TYPE_IDS = {
  MCQ: 1,
  SHORT_ANSWER: 2,
  IMAGE_ITEM: 3,
};

export const ITEM_STATUS_IDS = {
  PENDING: 1,
  ACTIVE: 2,
  RETIRED: 3,
  REJECTED: 4,
};

export const EXAM_STATUS_IDS = {
  PENDING: 1,
  ACTIVE: 2,
  RETIRED: 3,
  REJECTED: 4,
};

export const LEARNER_EXAM_STATUS_IDS = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'complete',
  LOCKED: 'locked',
  MARKED: 'marked',
};

export const LEARNER_EXAM_ANSWER_STATUSES = {
  INCOMPLETE: 'Incomplete',
  FLAGGED: 'Flagged',
  COMPLETE: 'Complete',
};

export const QUALIFICATION_TYPE_IDS = {
  QUALIFICATION: 1,
  APPRENTICESHIP: 2,
};

export const ADMIN_URL = 'backoffice';

export const OFFLINE_EXAM_LIFETIME = 30 * 24 * 60 * 60 * 1000; // 30 days