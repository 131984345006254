import React from 'react';
import ReactDOM from 'react-dom';
import SwitchApp from "SwitchApp";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <SwitchApp/>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();